.ninja-loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ninja-loader > img {
  width: 50%;
  mix-blend-mode: multiply;
}

@media screen and (max-width: 1080px) {
  .ninja-loader > img {
    width: 100%;
  }
}

/* custom loader */
.cta-loader-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.table-loader {
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid #5c5c5c;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 640px) {
  .table-loader {
    width: 15px;
    height: 15px;
  }
}
/* custom loader */

/* loading section */
.loading-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 2rem 0rem;
}

/* loading section */

/* No data found */
.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.no-data-found > p {
  font-family: "Caveat", cursive;
  font-size: 32px;
  color: #1976d2;
  font-weight: 800;
  margin-top: -1rem;
  text-align: center;
}

.no-data-found > img {
  width: 12%;
  mix-blend-mode: multiply;
}
/* No Data found */

@media screen and (max-width: 992px) {
  .no-data-found > p {
    margin-top: 0rem;
  }
}

@media screen and (max-width: 640px) {
  .no-data-found > img {
    width: 50%;
  }
  .no-data-found > p {
    margin-top: -2rem;
    margin-bottom: 1rem;
  }
}

.no-routes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.no-routes > img {
  width: 40%;
  margin-bottom: -2.5rem;
  mix-blend-mode: multiply;
}

.no-routes > p {
  font-family: "Caveat", cursive;
  font-size: 32px;
  color: #1976d2;
  font-weight: 800;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .no-routes > img {
    width: 55%;
    margin-bottom: -2rem;
  }
}
