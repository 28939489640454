@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
/* Reset css code */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  scrollbar-width: thin;
}

body,
html {
  padding-right: 0px !important;
  overflow-x: hidden;
  background-color: #f5f5ff;
  scroll-behavior: smooth;
  transition: 0.5s ease-in-out;
}

body {
  overflow: hidden;
}
/* Reset css code */

/* font family include */
@font-face {
  font-family: Poppins_Bold;
  src: url("./Assets/Poppins-Fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins_Medium;
  src: url("./Assets/Poppins-Fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins_Regular;
  src: url("./Assets/Poppins-Fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins_SemiBold;
  src: url("./Assets/Poppins-Fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Inter_Regular;
  src: url("./Assets/Inter-Fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: Montserrat_Bold;
  src: url("./Assets/Montserrat-Fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat_MediumItalic;
  src: url("./Assets/Montserrat-Fonts/Montserrat-MediumItalic.ttf");
}
/* font family include */

/* scrollbar narrow css  */
/* * {
  scrollbar-width: thin;
  scrollbar-color: #888 #f6f6f6;
  scroll-snap-type: proximity;
} */

/* *::-webkit-scrollbar-track {
  border-radius: 50px;
} */

/* *::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
} */

.audio-recorder-options {
  display: none !important;
}

.bottom-train-ui-grid-row::-webkit-scrollbar {
  height: 8px;
}

.bottom-train-ui-grid-row::-webkit-scrollbar-track {
  background: #ffffff;
}

.bottom-train-ui-grid-row::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

/* scrollbar narrow css */

/* main dashboard css for all screens layout starts here */
.grid-sidebar-row {
  display: grid;
  grid-template-columns: 263px 4fr;
}

.grid-sidebar-row > div {
  height: auto;
}

@media screen and (max-width: 1280px) {
  .grid-sidebar-row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.wrapper-container {
  padding: 20px 30px 20px 30px;
}

.wrapper-container-fluid {
  margin-top: 5.5rem;
}

.heading > h1 {
  font-size: 32px;
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) {
  .heading > h1 {
    font-size: 26px;
    line-height: 1.2195;
    margin-bottom: 10px;
  }
  .wrapper-container {
    padding: 20px 15px 20px 15px;
  }
}

.meeting-host-container > h2 {
  font-size: 28px;
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  margin: 5px 0px 20px 0px;
}

/* main dashboard css for all screens layout ends here */

/* dropdown css starts here */
.dropdown-grid-row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

@media screen and (max-width: 992px) {
  .dropdown-grid-row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 640px) {
  .dropdown-grid-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 576px) {
  .mobile-recurring-row {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

.input-tags {
  margin-top: 10px;
}

.input-tags > textarea {
  width: 100%;
  border-radius: 5px;
  margin-top: 4px;
  border: 1px solid #4549e847;
  margin-bottom: 5px;
  font-family: "Poppins_Medium";
  padding: 10px 15px;
}

.input-tags-input > input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #4549e847;
  font-family: "Poppins_Medium";
  padding: 10px 15px;
  height: 48px;
  margin-top: 5px;
}

.input-tags-input > input::placeholder {
  color: #757575;
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #757575;
  font-family: "Poppins_SemiBold";
  font-size: 12px;
}
.create-cta-container {
  text-align: center;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.image-row-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.image-row-container .img {
  width: 80px;
  overflow-clip-margin: unset;
  overflow: hidden;
  object-fit: cover;
  height: 80px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.create-cta-container > button {
  background-color: #4549e8 !important;
  height: 44px;
  width: 142px;
  min-width: 142px !important;
  text-transform: capitalize !important;
  color: white !important;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  border-radius: 2px !important;
  margin-bottom: 10px;
}
/* dropdown css ends here */

/* tooltip css  */
.MuiTooltip-tooltip {
  font-family: "Poppins_Medium" !important;
  background-color: #4549e8 !important;
  font-size: 12px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 16px 35px 0px rgba(0, 0, 0, 0.1), 0px 64px 64px 0px rgba(0, 0, 0, 0.09),
    0px 143px 86px 0px rgba(0, 0, 0, 0.05),
    0px 255px 102px 0px rgba(0, 0, 0, 0.01),
    0px 398px 111px 0px rgba(0, 0, 0, 0) !important;
}

.MuiTooltip-tooltip > span {
  color: #4549e8 !important;
}
/* tooltip css */

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
  border-width: 0px !important;
  border: 1px solid #4549e847 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: 4549e847 !important;
}

#__react-alert__ > div > div > div {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  text-transform: capitalize !important;
  font-family: "Poppins_SemiBold" !important;
  z-index: 99999999999 !important;
  border-radius: 0px !important;
  padding: 10px 30px 10px 30px !important;
  /* margin-top: 0rem !important; */
  background-color: #0f0f0f !important;
  border-radius: 2px !important;
  box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.12) !important;
}

.css-894a34-indicatorSeparator {
  background-color: transparent !important;
}

.loading-section > img {
  mix-blend-mode: multiply;
  width: 50%;
}

.MuiPickersDay-root,
.MuiTypography-caption,
.MuiMenuItem-gutters,
.MuiPickersCalendarHeader-label,
.MuiPickersYear-yearButton,
.MuiDialogActions-root > button {
  font-family: "Poppins_Medium" !important;
  font-size: 12px !important;
}

.date-picker-filter > div,
.date-picker-filter-new-border > div {
  flex-direction: column !important;
  width: 100%;
  padding-top: 5px !important;
}

.date-picker-filter > div > div > div > fieldset {
  border-color: transparent !important;
  border: none !important;
}

.date-picker-filter-new-border fieldset {
  border-color: #4549e847 !important;
  border-width: 1px !important;
}

.date-picker-filter
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.date-picker-filter-new-border
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.date-picker-filter-new-border
  .css-19a06rs-MuiFormControl-root-MuiTextField-root
  .MuiOutlinedInput-root {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.react-daterange-picker__wrapper {
  background-color: rgba(240, 240, 240, 1);
  padding: 5px 20px;
  border-radius: 23px;
  border: none !important;
}

.react-daterange-picker__inputGroup__input {
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
}

.react-calendar__month-view__weekdays {
  font-family: "Poppins_Medium" !important;
  color: white !important;
}

.react-calendar button {
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
  color: white !important;
}

.react-daterange-picker__calendar .react-calendar {
  background-color: #005e9e !important;
}

.react-daterange-picker__calendar {
  width: 250px !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #37b99c !important;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.react-calendar {
  border: none !important;
  border-radius: 5px !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #37b99c !important;
}

.react-daterange-picker__inputGroup {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.swiper {
  margin-left: initial !important;
  margin-right: initial !important;
}

.react-calendar__navigation button:disabled {
  color: #000 !important;
  background-color: #c7c7c7;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
}

input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 15px;
  height: 15px;
  border: none;
  border-radius: 2px;
  background-color: #dcdcdc;
  cursor: pointer;
}

input[type="checkbox"] + label::before {
  content: "\2713";
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: white;
  opacity: 0;
}

input[type="checkbox"]:checked + label {
  background-color: #005e9e;
}

input[type="checkbox"]:checked + label::before {
  opacity: 1;
}

/* followups */
.followup-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.followup-header-buttons > div:nth-child(2) > button {
  margin: 20px 5px 10px 5px;
  font-family: "Poppins_SemiBold" !important;
  font-size: 14px !important;
  color: white;
  height: 42px !important;
  text-transform: capitalize;
  border-radius: 2px !important;
}

.followup-header-buttons > div:nth-child(2) > button:nth-child(1) {
  background-color: #00a1ff !important;
}

.followup-header-buttons > div:nth-child(2) > button:nth-child(2) {
  background-color: #962dff !important;
}

.followup-header-buttons > div:nth-child(2) > button:nth-child(3) {
  background-color: #d42e2e !important;
}

.rhap_progress-indicator {
  width: 10px !important;
  height: 10px !important;
  top: -3px !important;
}

.followup-calls > div > input {
  height: 48px;
  border: 1px solid #4549e847;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px 10px;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  margin-top: 5px;
}

.followup-calls {
  margin-top: 10px;
}

.followup-calls .mt-2 {
  margin-top: 15px;
}

.date-range-picker-filter > div > div {
  height: 48px !important;
  border-radius: 5px !important;
  background-color: #e5e6ff !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "Poppins_SemiBold" !important;
  color: hsl(0, 0%, 50%) !important;
}

.date-picker-filter label,
.date-picker-filter-new-border label {
  font-size: 13px !important;
  font-family: "Poppins_SemiBold" !important;
  color: hsl(0, 0%, 50%) !important;
  z-index: initial !important;
  top: 1px;
}

.icon-profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.auto-wrap {
  max-width: 280px;
  overflow-wrap: break-word;
  white-space: normal;
}

.number-duration {
  margin-top: 6px !important;
  border-radius: 5px !important;
  font-family: "Poppins_Bold" !important;
  font-size: 12px;
  width: 100% !important;
  background-color: rgba(229, 230, 255, 1) !important;
  color: rgba(28, 28, 28, 0.6) !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.number-duration::placeholder {
  color: rgba(28, 28, 28, 0.5) !important;
}

.date-picker-filter div,
.date-picker-filter-new-border div {
  font-family: "Poppins_Medium" !important;
  font-size: 12px !important;
}

.date-picker-filter input,
.date-picker-filter-new-border input {
  height: 48px !important;
  padding: 0px 10px !important;
}

.subject-input {
  height: 48px;
  width: 100%;
  background-color: #e5e6ff;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px !important;
  font-family: "Poppins_SemiBold" !important;
  color: hsl(0, 0%, 50%) !important;
}

.subject-input::placeholder {
  color: hsl(0, 0%, 50%);
  opacity: 1; /* Firefox */
}

.subject-input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: hsl(0, 0%, 50%);
}

.date-picker-filter > div > div,
.date-picker-filter-new-border > div > div {
  min-width: fit-content !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  z-index: initial !important;
}

@media screen and (max-width: 576px) {
  .heading-flex-row > div:nth-child(2) {
    margin: 10px 0px 10px 0px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-container > div:nth-child(3)::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-container > div:nth-child(3) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rti--tag {
  border-radius: 2px !important;
}

.reset-filter-cta > button {
  background-color: #4549e8 !important;
  height: 48px;
  margin-top: 5px;
  color: white !important;
  font-family: "Poppins_SemiBold";
  padding: 0px 20px 0px 20px;
  font-size: 14px;
  border-radius: 2px !important;
  text-transform: capitalize;
}

#error {
  margin-top: 10px;
}

.MuiAlert-message {
  font-family: "Poppins_Medium";
  font-size: 12px;
  text-align: left;
}
/* 
.css-1pxa9xg-MuiAlert-message {

} */

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled:hover {
  cursor: not-allowed !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  pointer-events: initial !important;
}

@media screen and (max-width: 714px) {
  .followup-header-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 640px) {
  .followup-header-buttons > div:nth-child(2) > button:nth-child(3) {
    margin-top: 1px;
  }
}

@media screen and (max-width: 576px) {
  .followup-header-buttons > div:nth-child(2) > button:nth-child(2) {
    margin-top: 1px;
  }
}

.tag-no {
  margin-bottom: -1.5rem;
}

.Active_input > div > input,
.Active_input > div > textarea {
  color: #808080 !important;
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
}

button {
  transition: 0.3s ease-in !important;
}

.profile-image-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

button:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0) !important;
}

.selected-dates {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
}

.selected-dates > button {
  background-color: #c7c7c7 !important;
  color: #1c1c1c !important;
  padding: 2px 5px;
  font-family: "Poppins_Medium" !important;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 2px !important;
}

button:disabled {
  /* color: #999 !important; */
  background-color: #999 !important;
}

.audio-recorder-mic {
  height: 20px !important;
}

.followup-select-box {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.select-label {
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Poppins_SemiBold";
}

.followup-calls-entry {
  background-color: rgba(229, 230, 255, 1);
  border: none !important;
  font-size: 12px !important;
  color: rgba(131, 134, 199, 1) !important;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px 10px;
  font-family: "Poppins_SemiBold";
  height: 48px;
  margin-top: 5px;
}

.rtl-icon {
  position: relative;
  top: 0;
}

.rtl-icon > img {
  position: absolute;
  top: 14px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translateY(-5);
  -moz-transform: translateY(-5);
  -ms-transform: translateY(-5);
  -o-transform: translateY(-5);
}

.rtl-icon > input {
  padding-left: 50px !important;
}

.header-row-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-row-arrow > div > h1 {
  font-size: 32px;
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  text-transform: uppercase;
}

.heading-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 598px) {
  .header-row-arrow > div > h1 {
    line-height: 1.1195;
  }
}

.audio-grid-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px 15px 0px;
  padding: 15px 15px;
  background-color: rgba(229, 230, 255, 1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.audio-grid-row > div > p {
  font-family: "Poppins_SemiBold";
  color: rgba(1, 18, 34, 1);
  font-size: 14px;
}

.audio-grid-row > div > button {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px !important;
  min-width: 32px !important;
  height: 32px !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}
