.header-wrapper {
  background: #fff;
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  max-width: 100%;
  width: 100%;
  z-index: 2;
  right: 0;
  padding-left: 250px;
}

.header-grid-row {
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 2rem;
}

.header-grid-row > div {
  height: auto;
  padding: 20px 40px 20px 60px;
}

.header-grid-row > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.header-grid-row > div:nth-child(2) > img {
  margin-right: 8px;
}

.search-area {
  position: relative;
  top: 0;
  max-width: fit-content;
}

.search-area > input {
  background: #e5e6ff;
  border-radius: 5px;
  border: none;
  width: 549px;
  outline: none;
  padding: 0px 15px 0px 15px;
  height: 48px;
  font-family: "Poppins_Medium";
  font-size: 14px;
  color: #1c1c1c;
  overflow-y: hidden;
}

::placeholder {
  color: #1c1c1c;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1c1c1c;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1c1c1c;
}

.search-btn {
  position: absolute !important;
  background: #4549e8 !important;
  box-shadow: 0px 0px 0px 0px rgba(82, 78, 183, 0.1),
    0px 6px 13px 0px rgba(82, 78, 183, 0.1),
    0px 24px 24px 0px rgba(82, 78, 183, 0.09),
    0px 55px 33px 0px rgba(82, 78, 183, 0.05),
    0px 98px 39px 0px rgba(82, 78, 183, 0.01),
    0px 153px 43px 0px rgba(82, 78, 183, 0);
  border: none;
  cursor: pointer;
  height: 46px;
  top: 1px;
  right: 0;
  padding: 0px 20px 0px 20px;
  border-radius: 5px;
}

.avatar-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.avatar-flex-row > div:nth-child(1) {
  width: 40px;
  height: 40px;
  background-color: #4549e8;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Poppins_SemiBold";
  font-size: 16px;
  color: white;
}

.avatar-flex-row > div > p {
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  color: #1c1c1c;
}

.avatar-flex-row > div > button {
  font-family: "Poppins_Medium";
  font-size: 13px;
  text-decoration: none;
  color: #f8b552;
  text-transform: uppercase;
  padding: 0 !important;
}

.menu-icon {
  position: fixed !important;
  right: 20px !important;
  top: 20px !important;
  cursor: pointer;
  display: none !important;
  z-index: 999;
  border-radius: 50% !important;
  min-width: 30px !important;
  min-height: 30px !important;
}

.menu-icon > img {
  width: 30px;
}

@media screen and (max-width: 1280px) {
  .header-wrapper {
    width: 100%;
  }
  .header-wrapper {
    margin-left: 0px;
  }
  .menu-icon {
    display: block !important;
  }
}

@media screen and (max-width: 992px) {
  .header-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }

  .header-grid-row > div:nth-child(1) {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .search-area > input {
    width: 450px;
  }

  .header-grid-row > div {
    padding: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 576px) {
  .search-area > input {
    width: 250px;
  }

  .search-btn {
    padding: 0px 10px 0px 10px;
  }
}

@media screen and (max-width: 1280px) {
  .header-wrapper {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1536px) {
  .wrapper-container {
    max-width: 1440px;
    margin: auto;
  }
  .wrapper-picker-container {
    max-width: 1440px;
    margin: 6rem auto;
  }
  .menu-item {
    background-size: 232% 100%;
  }
}

.header-grid-row > div:nth-child(2) {
  gap: 10px;
}

@media screen and (min-width: 1100px) {
  .header-grid-row > div:nth-child(2) {
    justify-content: center;
  }
}

.link-icon {
  font-size: 1.6rem !important;
  color: white;
  cursor: pointer;
}
