.logo {
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #4549e8;
  padding-bottom: 20px;
  z-index: 9999;
}

.logo > img {
  margin-top: 1rem;
  width: 150px;
}

.sidebar-menu {
  height: 100vh;
  background-color: #4549e8;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
  z-index: 11;
  width: 263px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-menu {
  scrollbar-width: thin;
}

/* Hide scrollbar for IE, Edge and Firefox */

.toggle-icon {
  width: 25px;
}

.menu-item {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: white;
  font-family: "Poppins_Medium";
  margin: 10px 15px 10px 0px;
  padding: 12px 12px 12px 20px;
  gap: 10px;
  font-size: 14px;
  /* border-radius: 0px 7px 7px 0px; */
  background: linear-gradient(to left, transparent 50%, white);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease;
  position: relative;
  top: 0;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active {
  background: linear-gradient(to left, transparent 0%, white);
  color: black;
}

.menu-wrapper > a > p {
  white-space: nowrap;
}

.menu-item:hover {
  background-position: left bottom;
}

.routes {
  margin: 10px 0px 10px 0px;
  margin-top: 0.5rem;
}

.menu-wrapper::after {
  content: "";
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  width: 130px;
  display: block;
  margin: 0 auto;
}

/* mobile responsive */
.visible {
  transform: translateX(0);
}

.hidden {
  transform: translateX(-310px);
}

.menu-wrapper {
  position: relative;
  top: 0;
}

.menu-wrapper > svg {
  position: absolute;
  top: 12px;
  right: 25px;
  color: white;
  cursor: pointer;
  margin-right: -8px;
}

.animate {
  animation-name: slideIn;
  animation-duration: 1s;
  transition: 0.5s ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 992px) {
  .menu-item {
    background-size: 210% 100%;
  }
}
